<template lang="html">
  <div>
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">聊天会话</h3>

        <!--<div class="card-tools mr-0">
            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                <i class="fas fa-minus"></i>
            </button>
        </div>-->
      </div>
      <div class="card-body p-0">
        <ul class="nav nav-pills flex-column">
          <li v-for="(message) in list" class="nav-item">
            <router-link :to="{ name: 'Chat', params: { userName: message.fromUser.userName }}" class="dropdown-item">
              <!-- Message Start -->
              <div class="media">
                <img v-if="message.fromUser.avatarUrl" :src="message.fromUser.avatarUrl.replace('/0/0/0/0/', '/100/100/1/0/')" alt="User Avatar" class="img-size-50 mr-3 img-circle">
                <img v-else src="/images/avatar-default.jpg" alt="User Avatar" class="img-size-50 mr-3 img-circle">
                <div class="media-body">
                  <h3 class="dropdown-item-title">
                    {{message.fromUser.nickName}}
                    <span v-if="message.count" class="float-right badge badge-danger">{{message.count}}</span>
                  </h3>
                  <div class="text-sm text-truncate" style="width:200px;">{{message.text}}</div>
                  <time class="text-sm text-muted"><i class="far fa-clock mr-1"></i>{{$formatTime(message.addTime)}}</time>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
      <!-- /.card-body -->
      <div v-if="updateing" class="overlay">
        <i class="fas fa-spinner fa-pulse"></i>
      </div>
    </div>

  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { Modal } from 'common'
  //import Modal from '@/components/Modal'
  export default {
    components: {
      Modal
    },
    data() {
      return {
        list: [],
        pager: {
          prePageSize: [10, 20, 50, 100],
          pageIndex: 1,
          pageSize: 1,
          totalPages: 0,
          totalRecords: 0
        },
        query: {
          pageIndex: 1,
          pageSize: 20,
          sort: "Id",
          order: "desc",
          fields: 'Name',
          key: null,
          roles: "",
          type: ''
        },
        types: [],
        updateing: false,
        hasMore: true,
      }
    },
    computed: {

    },
    created() {
      this.loadData()
      this.$mainHub.$on('message-received', this.onMessageReceived)

    },
    beforeDestroy() {
      this.$mainHub.$off('message-received', this.onMessageReceived)
    },
    methods: {
      onMessageReceived({ message }) {
        console.log('cant init ', message)
        for (var i in this.list) {
          if (this.list[i].fromUser.userName === message.user.userName) {
            var item = this.list[i]
            console.log('init ', item)
            item.addTime = message.addTime
            item.text = message.text
            item.count++
            this.list.splice(i, 1)
            this.list.splice(0, 0, item)
            return
          }
        }
      },
      loadData() {
        let _this = this
        this.updateing = true
        var url = "/Api/My/Messages/TalkList"
        this.$axios.get(url)
          .then(function (response) {
            console.log(response)
            _this.list = (response.data || []).reverse()
            //var div = document.querySelector(".direct-chat-messages")
            //setTimeout(function () { div.scrollTop = div.scrollHeight }, 1)
            //_this.hasMore = response.data.length == params.pageSize;
            _this.updateing = false
          }).catch(function (error) {
            console.log(error)
            _this.updateing = false
          })
      },
      AppendData() {
        let _this = this
        var url = "/Api/My/Messages/Talks"
        var params = {
          toUserName: this.message.toUserName,
          pageSize: this.query.pageSize,
          lastTime: this.messages[0].addTime
        }
        this.$axios.get(url, { params: params })
          .then(function (response) {
            console.log(response)
            _this.messages = [...(response.data || []).reverse(), ..._this.messages]
            _this.hasMore = response.data.length == params.pageSize;
            var div = document.querySelector(".direct-chat-messages")
            setTimeout(function () {
              var obj = document.querySelector('#msg_' + _this.$moment(params.lastTime).unix())
              $(div).scrollTop(obj.offsetTop - 32)
              //$(div).animate({ scrollTop: obj.offsetTop-32 }, 0)
              //$(div).fadeIn(1000);
            }, 1)
          }).catch(function (error) {
            console.log(error)
          })
      },
      search() {
        this.query.pageIndex = 1;
        this.loadData();
      },
      send() {
        let _this = this
        if (_this.message.text == '' || _this.message.text == null) {
          return
        }
        _this.updateing = true;
        this.$axios.post('/Api/My/Messages', _this.message)
          .then(function (response) {
            _this.message.text = ''
            //_this.reset()
            //_this.$refs.Edit.hide()
            //_this.loadData()
            _this.updateing = false;
          })
          .catch(function (error) {
            console.log(error)
            _this.updateing = false;
          })
      },
      formatTime(time) {
        var mTime = this.$moment(time)
        if (mTime.toDate() < this.$moment().subtract(6, 'days').toDate()) {
          return mTime.format("YYYY-MM-DD HH:mm:ss")
        }
        else if (mTime.toDate() < this.$moment().subtract(1, 'days').toDate()) {
          return mTime.format("dddd HH:mm:ss")
        }
        else if (mTime.toDate() < this.$moment().subtract(4, 'hours').toDate()) {
          return mTime.format("HH:mm:ss")
        }
        else {
          return mTime.fromNow()
        }
      }

    },
    //beforeRouteUpdate(to, from, next) {
    //    this.message.toUserName = to.params.userName
    //    this.loadData()
    //    next()
    //}
  }
</script>
<style lang="css">
  .direct-chat-messages {
    height: 375px;
  }

  .direct-chat-text {
    margin-left: 10px;
    display: inline-block;
    max-width: calc(100% - 50px);
  }

  .right .direct-chat-text {
    margin-right: 10px;
    margin-left: auto;
    float: right;
  }
</style>
